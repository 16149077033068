import React, { Suspense, lazy } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";

import CustomNavbar from "./components/navbar/Navbar";
import Result from "./routes/Result";
const Developer = lazy(() => import("./routes/Developer"));

import { NAVBAR_BLUE } from "./utils/constants";

// Rendered when page is loading
const renderLoader = () => (
  <>
    <main className="d-flex justify-content-center">
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ width: "80vw", height: "50vh" }}
      >
        <CircularProgress size="60px" style={{ color: NAVBAR_BLUE }} />
      </div>
    </main>
  </>
);
export default function Router() {
  return (
    <>
      <CustomNavbar />
      <main>
        <Suspense fallback={renderLoader()}>
          <Switch>
            <Route exact path="/" component={() => <Result />} />
            <Route exact path="/developer" component={Developer} />
            <Redirect to="/" />
          </Switch>
        </Suspense>
      </main>
    </>
  );
}
