import React, { useCallback, useEffect, useMemo } from "react";
import { useDropzone } from "react-dropzone";

import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import imageUnavailable from "url:../../assets/images/imageUnavailable.svg";

import callAPI from "../../utils/callAPI";
import useAsyncError from "../../customHooks/asyncError";
import changeDateFormat from "../../utils/formatDate";

const Input = styled("input")({
  display: "none",
});

const baseStyle = {
  display: "flex",
  height: "100%",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  color: "#bdbdbd",
  transition: "border .3s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export default function ImageUpload({
  files,
  setFiles,
  setIsProcessing,
  setFields,
  setImageURL,
}) {
  const throwError = useAsyncError();

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );

    const formData = new FormData();
    formData.append("data", acceptedFiles[0]);

    setIsProcessing(true);
    callAPI("POST", `upload/`, formData)
      .then((res) => {
        setFields(() => {
          const response = {
            documentType: res.documentType,
            documentID: res.documentID,
            name: res.Name,
            gender: res.Gender,
            dateOfBirth: res["Date Of Birth"].includes("/")
              ? changeDateFormat(res["Date Of Birth"])
              : res["Date Of Birth"],
            address: res.Address,
          };
          return response;
        });

        setImageURL(res.imageURL);
        setIsProcessing(false);
      })
      .catch((error) => {
        throwError(new Error(error));
      });
  }, []);

  const handleRemove = (file) => {
    const newFiles = [...files];
    newFiles.splice(file, 1);
    setFiles(newFiles);
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: false,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  // clean up
  useEffect(() => {
    return () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    };
  }, [files]);

  return (
    <div className="w-100">
      {!files.length >= 1 ? (
        <>
          <div className="upload__container container h-100 p-0">
            <div {...getRootProps({ className: "dropzone", style })}>
              <div className="row pt-5 d-flex justify-content-center align-items-center">
                <div className="col-12 upload__img  d-flex justify-content-center align-items-center">
                  <img src={imageUnavailable} height="100" width="100" />
                </div>
              </div>
              <div className="row mt-4 pb-5">
                <div className="col-12 d-flex justify-content-center align-items-center">
                  <label htmlFor="contained-button-file">
                    <Input {...getInputProps()} />
                    <Button
                      className="text-light"
                      color="secondary"
                      variant="contained"
                      component="span"
                    >
                      Select Image
                    </Button>
                    <p className="mt-2">or drop image here</p>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <aside className="thumbnail__container mb-5" style={style}>
          {files.map((file) => (
            <div
              className="d-flex flex-column align-items-center justify-content-center"
              key={file.name}
            >
              <div className="d-flex flex-column align-items-center justify-content-center">
                <img src={file.preview} alt={file.name} />
              </div>
              <HighlightOffIcon
                className="mt-2"
                style={{ color: "#ff1744" }}
                type="button"
                onClick={() => handleRemove(file)}
              />
            </div>
          ))}
        </aside>
      )}
    </div>
  );
}
