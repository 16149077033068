const API_HOST = process.env.REACT_APP_API_HOST;
const HOME = process.env.REACT_APP_HOME;

export { API_HOST };
export { HOME };
export const PERCEPTIVITI = "https://www.perceptiviti.com/";
export const HOME_TAB = "Home";
export const DEVELOPER_TAB = "Developer";

export const WHITE = "#FFFFFF";
export const BLACK = "#000000";
export const BACKGROUND_GREY = "#F7F7F7";
export const NAVBAR_BLUE = "#305697";
export const BORDER_GREY = "#E8E9EC";
export const TEXT_GREY = "#4D4F5C90";
export const BULLET_GREY = "#EDECFE";
export const HIGHLIGHT_BLUE = "#80bdff";

export const RED = "#df426b";
export const GREEN = "#4caf50";
export const ORANGE = "#ffc107";

export const LETTER_SPACING = "0.1rem";
export const INPUT_BOX_RADIUS = "15px";
export const INPUT_BOX_BACKGROUND = "white";
export const BUTTON_BORDER_RADIUS = "5px";
export const BOX_SHADOW = "5px 4px 30px rgba(0,0,0,.10)";
