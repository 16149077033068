import React, { useEffect, useState } from "react";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import CircularProgress from "@mui/material/CircularProgress";
import ZoomInRoundedIcon from "@mui/icons-material/ZoomInRounded";
import ZoomOutRoundedIcon from "@mui/icons-material/ZoomOutRounded";

import Input from "@mui/material/Input";
import Button from "@mui/material/Button";

import { API_HOST, NAVBAR_BLUE } from "../utils/constants";
import ImageUpload from "../components/imageUpload/ImageUpload";
import { TextField, TextareaAutosize } from "@mui/material";

// import {
//   DEVELOPER_TAB,
//   HOME_TAB,
//   PERCEPTIVITI,
//   WHITE,
//   API_HOST,
//   NAVBAR_BLUE,
// } from "../../utils/constants";

const borderStyle = {
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
};

const initialState = {
  documentType: "",
  documentID: "",
  name: "",
  gender: "",
  dateOfBirth: "",
  address:"",
};

export default function Result() {
  const [isProcessing, setIsProcessing] = useState(false);

  const [files, setFiles] = useState([]);
  const [imageURL, setImageURL] = useState();
  const [fields, setFields] = useState(initialState);
  const [ addressFiles, setAddressFiles] = useState([]);
  const [ addressImageURL, setAddressImageURL] = useState();
  const [ addressFields, setAddressFields] = useState(initialState);
  const [ addressProcessing, setAddressProcessing] = useState(false);

  useEffect(() => {
    document.addEventListener("dragenter", handleDrop, false);

    return () => {
      document.removeEventListener("dragenter", handleDrop, false);
    };
  });

  const handleChange = (event) => {
    const fieldName = event.target.name;
    let fieldValue = event.target.value;
    if (fieldName === "documentID" && fields.documentType === "AADHAR") {
      formattedText = fieldValue.split(" ").join(""); // remove space
      if (formattedText.length > 0) {
        fieldValue = formattedText.match(new RegExp(".{1,4}", "g")).join(" "); // add space after 4 characters
      }
    }
    setFields(() => ({ ...fields, [fieldName]: fieldValue }));
  };

  const handleDrop = (event) => {
    setImageURL(null);
    setFiles([]);
    setFields(initialState);
  };

  return (
    <section className="result container-fluid d-block">
      {addressProcessing ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ width: "80vw", height: "50vh" }}
        >
          <CircularProgress size="60px" style={{ color: NAVBAR_BLUE }} />
        </div>
      ) : (
        <>
          <div className="row p-4 h-100" style={borderStyle}>
            <div className="col-12 thumbnail__container h-100 ">
              {imageURL && addressImageURL? (
                <div className="d-flex h-100 justify-content-center gap-10 px-2">
                  <div className="w-100 h-100 d-flex justify-content-center">
                  <TransformWrapper
                    defaultScale={1}
                    defaultPositionX={200}
                    defaultPositionY={100}
                    wheel={{ disabled: 1 }}
                    doubleClick={{ disabled: 1 }}
                  >
                    {({ zoomIn, zoomOut, resetTransform }) => (
                      <>
                        <div className="thumbnail-buttons m-2">
                          <Button
                            size="large"
                            className="text-light"
                            variant="contained"
                            color="primary"
                            style={{ marginRight: "1rem" }}
                            onClick={() => zoomIn()}
                          >
                            <ZoomInRoundedIcon />
                          </Button>
                          <Button
                            size="large"
                            className="text-light"
                            variant="contained"
                            color="primary"
                            style={{ marginRight: "1rem" }}
                            onClick={() => zoomOut()}
                          >
                            <ZoomOutRoundedIcon />
                          </Button>

                          <Button
                            size="large"
                            className="text-light mr-2"
                            style={{
                              fontSize: "13px",
                            }}
                            variant="contained"
                            color="primary"
                            onClick={() => resetTransform()}
                          >
                            Reset
                          </Button>
                        </div>
                        <TransformComponent
                          style={{ width: "100%", marginTop: "15%" }}
                        >
                          <img
                            src={`${API_HOST}${imageURL}`}
                            alt="Scanned document image"
                            style={{maxHeight: "400px"}}
                          />
                        </TransformComponent>
                      </>
                    )}
                  </TransformWrapper>
                  </div>
                  <div className="w-100 h-100 d-flex justify-content-center">
                  <TransformWrapper
                    defaultScale={1}
                    defaultPositionX={200}
                    defaultPositionY={100}
                    wheel={{ disabled: 1 }}
                    doubleClick={{ disabled: 1 }}
                  >
                    {({ zoomIn, zoomOut, resetTransform }) => (
                      <>
                        <div className="thumbnail-buttons m-2">
                          <Button
                            size="large"
                            className="text-light"
                            variant="contained"
                            color="primary"
                            style={{ marginRight: "1rem" }}
                            onClick={() => zoomIn()}
                          >
                            <ZoomInRoundedIcon />
                          </Button>
                          <Button
                            size="large"
                            className="text-light"
                            variant="contained"
                            color="primary"
                            style={{ marginRight: "1rem" }}
                            onClick={() => zoomOut()}
                          >
                            <ZoomOutRoundedIcon />
                          </Button>

                          <Button
                            size="large"
                            className="text-light mr-2"
                            style={{
                              fontSize: "13px",
                            }}
                            variant="contained"
                            color="primary"
                            onClick={() => resetTransform()}
                          >
                            Reset
                          </Button>
                        </div>
                        <TransformComponent
                          style={{ width: "100%", marginTop: "15%" }}
                        >
                          <img
                            src={`${API_HOST}${addressImageURL}`}
                            alt="Scanned document image"
                            style={{maxHeight: "400px"}}
                          />
                        </TransformComponent>
                      </>
                    )}
                  </TransformWrapper>
                  </div>
                </div>
                
              ) : (
                <div className="d-flex">
                <ImageUpload
                  files={files}
                  setFiles={setFiles}
                  setFields={setFields}
                  setImageURL={setImageURL}
                  setIsProcessing={setIsProcessing}
                />
                <div className="m-3"></div>
                <ImageUpload
                  files={addressFiles}
                  setFiles={setAddressFiles}
                  setFields={setAddressFields}
                  setImageURL={setAddressImageURL}
                  setIsProcessing={setAddressProcessing}
                />
                </div>
                
              )}
            </div>
          
            <div className="col-12 row d-flex pt-5">
              <div className="w-50 container align-items-center justify-content-center m-6">
                {/* <Box
                  component="form"
                  sx={{
                    "& > :not(style)": { m: 1 },
                  }}
                  noValidate
                  autoComplete="off"
                > */}
                <div className="row d-flex align-items-center">
                  <span className="text-muted col-4">Document Type: </span>
                  <div className="col-8">
                    <Input
                      className="fw-bold fs-6 w-100"
                      name="documentType"
                      onChange={handleChange}
                      value={fields.documentType}
                      placeholder="Document Type"
                    />
                  </div>
                </div>
                <div className="row mt-4 d-flex align-items-center">
                  <span className="text-muted col-4">Document ID: </span>
                  <div className="col-8">
                    <Input
                      className="fw-bold fs-6 w-100"
                      name="documentID"
                      onChange={handleChange}
                      value={fields.documentID}
                      placeholder="Document ID"
                    />
                  </div>
                </div>
                <div className="row mt-4 d-flex align-items-center">
                  <span className="text-muted col-4">Name: </span>
                  <div className=" col-8">
                    <Input
                      className="fw-bold fs-6 w-100"
                      name="name"
                      onChange={handleChange}
                      value={fields.name}
                      placeholder="Name"
                    />
                  </div>
                </div>
                <div className="row mt-4 d-flex align-items-center">
                  <span className="text-muted col-4">Date of Birth: </span>
                  <div className="col-8">
                    {fields.dateOfBirth.length !== 4 ||
                    fields.dateOfBirth.length === 0 ? (
                      <Input
                        className="fw-bold fs-6 w-100"
                        name="dateOfBirth"
                        type="date"
                        onChange={handleChange}
                        value={fields.dateOfBirth}
                      />
                    ) : (
                      <Input
                        className="fw-bold fs-6 w-100"
                        name="dateOfBirth"
                        type="text"
                        onChange={handleChange}
                        value={fields.dateOfBirth}
                      />
                    )}
                  </div>
                </div>
                <div className="row mt-4 d-flex align-items-center">
                  <span className="text-muted col-4">Gender: </span>
                  <div className="col-8">
                    <Input
                      className="fw-bold fs-6 w-100"
                      name="gender"
                      onChange={handleChange}
                      value={fields.gender}
                      placeholder="Gender"
                    />
                  </div>
                </div>
                <div className="row mt-4 d-flex align-items-center">
                  <span className="text col-4">Address: </span>
                  <div className="col-8">
                    <TextareaAutosize
                      className="fw-bold fs-6 w-100 text-wrap"
                      name="address"
                      onChange={handleChange}
                      value={addressFields.address || fields.address}
                      placeholder="Address"
                      maxRows={2}
                      style={{height: "70px", border: 0, borderBottom: "1px solid black"}}
                    />
                  </div>
                </div>
                {/* </Box> */}
              </div>
            </div>
          </div>
        </>
      )}
    </section>
  );
}